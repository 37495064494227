html, body {
    font-family: "Khand", sans-serif !important;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
  }
  
 

.container {
  width: 900px !important;
  max-width:100% !important;
}

body{  
  font-size: 17px !important;  
}
 
body a{
  color:#fff;
}

body a:hover{
  color:#fff; 
  text-decoration: underline;
}
 
  .page-loader {
    background: #fff;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 9999;
  }
  
  .loader-icon {
    top: 50%;
    left: 50%;
    right: 0;
    position: absolute;
    margin: -20px 0 0 -20px;
    bottom: 0;
  }
  
  .backstretch:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-image: url("../img/overlay.png");
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
  
  .logo img {
    max-width: 100%; 
    height: auto;
    width: 260px;
  }
  
  #video {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  
  #video:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-image: url("../img/overlay.png");
    background-color: rgba(95, 96, 96, 0.4);
    z-index: 1;
  }
  
  #page-loader {
    background: #303440;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 999;
  }
  
  .loader-icon {
    background: none repeat scroll 0 0 #303440;
    border-bottom: 4px solid rgba(19, 19, 19, 0.1) !important;
    border-left: 4px solid rgba(19, 19, 19, 0.1) !important;
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    -o-border-radius: 100px;
    -ms-border-radius: 100px;
    border-right: 4px solid rgba(19, 19, 19, 0.1) !important;
    border-top: 4px solid;
    height: 60px;
    left: 50%;
    margin: -20px 0 0 -20px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 60px;
  }
  
  .colored-border {
    border-color: #fff;
  }
  
  .fa-spin {
    -webkit-animation: fa-spin 1.2s infinite linear;
    animation: fa-spin 1.2s infinite linear;
  }
  
  @-webkit-keyframes fa-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @keyframes fa-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  ::-webkit-input-placeholder {
    color: #FFFFFF;
  }
  
  :-moz-placeholder {
    color: #FFFFFF;
    opacity: 1;
  }
  
  ::-moz-placeholder {
    color: #FFFFFF;
    opacity: 1;
  }
  
  :-ms-input-placeholder {
    color: #FFFFFF;
  }
  
  header { 
    height: 100%;
    width: 100%;
    overflow: hidden;
    color: #FFFFFF;
  }
  
  .d-flex{ 
    display: flex;
  }
  
  .welcome {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    padding-top: 0px;
    transform: translate(0, -50%);
  }
  
  header img {
    margin-top: 0px;
    position: relative;
    z-index: 99;
  }
  
  header p span{ 
    width: 50px
  }
  
  header p {
    margin-bottom: 3px;
    display: flex; 
    align-items: center;
  }
  
  header p .lnr.lnr-envelope:before { 
    position: relative;
    top: -2px;
  }
  
  header p .lnr.lnr-map-marker:before { 
    position: relative;
    top: -1px;
  }
   
  header p .lnr{ 
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
  }
  
  header hr {
    position: relative;
    display: block;
    border: 0;
    top: 4.5em;
    margin-bottom: 9em;
    width: 20%;
    height: 6px;
    border-top: solid 1px rgba(255, 255, 255, 0.7);
    border-bottom: solid 1px rgba(255, 255, 255, 0.7);
    z-index: 99;
  }
  
  header hr:before,
  header hr:after {
    content: "";
    position: absolute;
    top: -8px;
    display: block;
    width: 1px;
    height: 21px;
    background: rgba(255, 255, 255, 0.7);
  }
  
  header hr:after {
    right: -1px;
  }
  
  header .actions {
    margin: 13.5em 50px 0 0;
    position: relative;
    list-style: none;
    z-index: 99;
  }
  
  .actions li a {
    text-decoration: none !important;
  }
  
  header .actions:after {
    background: rgba(255, 255, 255, 0.7);
    content: "";
    height: 100vh;
    position: absolute;
    top: 100%;
    width: 1px;
    left: 50%;
  }
  
  header .social-icons li {
    list-style: none;
    float: left;
    margin: 0 20px 0 0;
  }
  
  header .social-icons li a {
    color: #fff;
    transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
  }
  
  header .social-icons li a:hover {
    color: rgba(236, 240, 241, 1);
  }
  
  header .social-icons {
    position: relative; 
  }
  
  header .social-icons ul {
    line-height: 0px;
    position: relative;
    display: inline-block;
    margin: 0;
    border-left: solid 0px rgba(255, 255, 255, 0.7);
    border-right: solid 0px rgba(255, 255, 255, 0.7);
    padding-left: 0px;
  }
  
  header .social-icons > ul:before,
  header .social-icons > ul:after {
    content: "";
    display: block;
    width: 600%;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    height: 5px;
    border-top: solid 1px rgba(255, 255, 255, 0.7);
    border-bottom: solid 1px rgba(255, 255, 255, 0.7);
    display: none;
  }
  
  header .social-icons > ul:before {
    left: 100%;
    margin-left: 1px;
  }
  
  header .social-icons > ul:after {
    right: 100%;
    margin-right: 1px;
  }
  
  header .social-icons li span {
    color: #fff;
    margin-right: 5px;
  }
  
  header .button {
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    background-color: transparent;
    border: solid 1px rgba(255, 255, 255, 0.7);
    color: #FFF !important;
    cursor: pointer;
    display: inline-block;
    font-size: 1.2em;
    font-weight: 400;
    height: 3em;
    letter-spacing: 0.125em;
    line-height: 3em;
    padding: 0 3em;
    list-style: none;
    text-align: center;
    text-decoration: none;
    text-indent: 0.125em;
    text-transform: uppercase;
    white-space: nowrap;
  }
  
  header .button:hover {
    background-color: rgba(144, 144, 144, 0.22);
  }
  
  @-webkit-keyframes scrolls {
    0% {
      top: 10px;
      opacity: 1;
      height: 4px;
    }
    95% {
      top: 18px;
      opacity: 0;
      height: 10px;
    }
    100% {
      top: 8px;
      opacity: 1;
      height: 4px;
    }
  }
  .countdown-section {
    text-align: center;
    padding: 100px 0 100px;
    background: #EB5054;
    border-bottom: 6px solid #EC676C;
  }
  
  .countdown {
    font-size: 7em;
    margin: 0 auto;
    line-height: 65px;
    display: inline-block;
    color: #FFFFFF;
    font-weight: 300;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .countdown div span {
    display: block;
    font-size: 19px;
    line-height: 35px;
  }
  
  .countdown div {
    display: inline-block;
    width: 190px;
    padding-top: 12px;
  }
  
  .countdown-section .fa-clock-o {
    font-size: 30px;
    color: #fff;
    margin-right: 5px;
    width: 30px;
  }
  
  .countdown-section h3 {
    color: #fff;
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 50px;
  }
  
  .border_clock {
    border-left: 1px solid rgba(255, 255, 255, 0.39);
  }
  
  .fa-clock-o {
    -webkit-animation: fa-spin 9s infinite linear;
    animation: fa-spin 9s infinite linear;
  }
  
  .services {
    padding: 100px 0 100px;
    background-color: #F2F2F2;
    z-index: 10;
  }
  
  .services .col-md-4 {
    margin-bottom: 30px;
  }
  
  .services .icon i.fa {
    background: rgba(22, 182, 234, 0);
    text-align: center;
    line-height: 75px;
    font-size: 27px;
    border-bottom: none;
    color: #394F6F;
    margin: 10px 10px 15px auto;
    display: inline-block;
    float: left;
    border-radius: 50%;
    border: 2px solid #394F6F;
    height: 80px;
    width: 80px;
  }
  
  .services h2 {
    font-size: 18px;
    margin-bottom: 15px;
    color: #50547A;
  }
  
  .services h3 {
    font-weight: 600;
    font-size: 30px;
    color: #000000;
    padding-bottom: 25px;
    letter-spacing: 1px;
    background: url(../img/h1-bg.png) 50% 100% no-repeat;
    text-align: center;
    margin-bottom: 50px;
  }
  
  .services p {
    text-align: left;
    font-size: 15px;
    word-spacing: 1px;
    line-height: 25px;
    margin-left: 90px;
    color: #50547A;
  }
  
  .about {
    padding: 100px 0 100px;
    background-color: #F2F2F2;
    z-index: 10;
  }
  
  .about h3 {
    font-weight: 600;
    font-size: 30px;
    color: #000000;
    padding-bottom: 25px;
    letter-spacing: 1px;
    background: url(../img/h1-bg.png) 50% 100% no-repeat;
    text-align: center;
    margin-bottom: 50px;
  }
  
  .about img {
    width: 100%;
  }
  
  .about h2 {
    font-size: 19px;
    margin-bottom: 15px;
    color: #50547A;
    font-weight: 500;
  }
  
  .about p {
    color: #50547A;
    text-align: left;
    word-spacing: 1px;
    line-height: 25px;
    font-size: 15px;
    margin-bottom: 15px;
  }
  
  .about i.fa {
    background: rgba(22, 182, 234, 0);
    text-align: center;
    line-height: 37px;
    list-style: none;
    font-size: 17px;
    border-bottom: none;
    color: #394F6F;
    margin: 10px 10px 15px auto;
    display: inline-block;
    float: left;
    border-radius: 50%;
    border: 2px solid #394F6F;
    height: 40px;
    width: 40px;
    transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
  }
  
  .about .social-icons li {
    list-style: none;
    float: left;
  }
  
  .about i.fa:hover {
    background: #394F6F;
    color: #fff;
  }
  
  .about .social-icons {
    margin-left: -40px;
    position: relative;
    z-index: 999;
  }
  
  .subscribe {
    background: #303440;
    padding: 100px 0 100px;
    z-index: 10;
    border-top: 6px solid #fff;
    border-bottom: 6px solid #393A4C;
  }
  
  .subscribe h2 {
    font-weight: 600;
    font-size: 30px;
    color: #fff;
    padding-bottom: 25px;
    letter-spacing: 1px;
    background: url(../img/h1-bg-white.png) 50% 100% no-repeat;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 50px;
  }
  
  .subscribe i.fa.fa-paper-plane {
    font-size: 29px;
    padding-bottom: 15px;
    display: block;
    text-align: center;
  }
  
  .subscribe #ajaxChimp {
    text-align: center;
  }
  
  .subscribe input[type=text] {
    background-color: rgba(255, 255, 255, 0);
    border: solid 2px rgba(255, 255, 255, 1);
    height: 54px;
    width: 30%;
    color: #fff;
    word-spacing: 1px;
    padding-left: 20px;
    font-size: 13px;
    outline: none;
  }
  
  .subscribe button {
    background-color: rgba(235, 80, 84, 1);
    border: 2px solid rgba(235, 80, 84, 1);
    color: #fff;
    width: 7%;
    outline: none;
    font-size: 13px;
    font-weight: 700;
    height: 54px;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
  
  .subscribe button:hover {
    background: transparent;
    border: solid 2px rgba(255, 255, 255, 1);
    color: #fff;
  }
  
  .subscribe label {
    height: 2.5em;
    padding: 15px 20px;
    color: #ecf0f1;
    font-size: 13px;
    float: none;
    top: 0;
    display: block !important;
    font-weight: 100;
  }
  
  .subscribe label.error i {
    color: #FFFFFF;
    padding-right: 3px;
  }
  
  .subscribe label i.fa.fa-check {
    padding-top: 15px;
  }
  
  .contact {
    background-color: #EB5054;
    padding: 100px 0 100px;
    z-index: 10;
    border-bottom: 6px solid #EC676C;
    border-top: 6px solid #fff;
  }
  
  .contact h2 {
    font-weight: 600;
    font-size: 30px;
    color: #fff;
    padding-bottom: 25px;
    letter-spacing: 1px;
    background: url(../img/h1-bg-white.png) 50% 100% no-repeat;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 50px;
  }
  
  .contact input[type=text], .contact input[type=email] {
    width: 44%;
    background: transparent;
    border-bottom: 1px solid #fff;
    color: #fff;
    border-top: none;
    border-left: none;
    border-right: none;
    height: 54px;
    font-size: 15px;
    outline: none;
    -webkit-transition: all 0.3s !important;
    -moz-transition: all 0.3s !important;
    transition: all 0.3s !important;
  }
  
  .contact input[type=email] {
    right: 15px;
    position: absolute;
    top: 0px;
  }
  
  .contact input[type=text] {
    width: 45.1%;
  }
  
  .contact textarea {
    width: 100%;
    background: transparent;
    padding-top: 15px;
    color: #fff;
    font-size: 15px;
    border-bottom: 1px solid #fff;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    resize: none;
    margin: 20px 0;
    -webkit-transition: all 0.3s !important;
    -moz-transition: all 0.3s !important;
    transition: all 0.3s !important;
  }
  
  .contact input[type=submit] {
    background-color: rgba(22, 182, 234, 0);
    border: 2px solid #FFFFFF;
    color: #FFFFFF;
    float: right;
    width: 11%;
    display: block;
    word-spacing: 1px;
    outline: none;
    font-size: 15px;
    padding-top: 3px;
    margin-top: 20px;
    font-weight: 300;
    height: 54px;
    -webkit-transition: all 0.3s ease-in-out !important;
    -moz-transition: all 0.3s ease-in-out !important;
    -ms-transition: all 0.3s ease-in-out !important;
    -o-transition: all 0.3s ease-in-out !important;
    transition: all 0.3s ease-in-out !important;
  }
  
  .contact input[type=submit]:hover {
    background: #FFFFFF;
    color: #000;
  }
  
  .contact .error .email {
    text-align: right;
    display: inline-block;
    position: absolute;
    right: 19px;
    padding-left: 9px;
  }
  
  .contact .error .name {
    display: inline-block;
    position: absolute;
    left: 43.5%;
    padding-left: 2px;
  }
  
  .contact .error .message {
    text-align: right;
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 115px;
    margin-right: 20px;
    padding-left: 9px;
  }
  
  .contact #ajax-contact-form i {
    color: #FFFFFF;
  }
  
  .contact .notification_ok {
    color: #FFFFFF;
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 16px;
    position: absolute;
    top: 80%;
    left: 15px;
    width: 96%;
    margin-top: 5px;
  }
  
  .contact .notification_ok i {
    color: #FFFFFF;
    padding-right: 5px;
  }
  
  #google-container {
    position: relative;
    width: 100%;
    height: 400px;
    background-color: #e7eaf0;
  }
  
  .google-map {
    position: relative;
  }
  
  #zoom-in, #zoom-out {
    height: 32px;
    width: 32px;
    cursor: pointer;
    margin-left: 50px;
    background-color: rgba(235, 80, 84, 0.9);
    background-repeat: no-repeat;
    background-size: 32px 64px;
    background-image: url("../img/icon-controller.svg");
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
  
  #zoom-in:hover, #zoom-out:hover {
    background-color: #EB5054;
  }
  
  #zoom-in {
    background-position: 50% 0;
    margin-top: 50px;
    margin-bottom: 1px;
  }
  
  #zoom-out {
    background-position: 50% -32px;
  }
  
  .google-map .contact-info {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    background: rgba(235, 80, 84, 0.9);
    color: #FFFFFF;
    margin-bottom: 0;
    letter-spacing: 1px;
    height: 54px;
  }
  
  .google-map address .fa-map-marker, .phone i, .email i {
    padding-right: 10px;
  }
  
  .google-map address {
    margin-bottom: 0;
    font-size: 16px;
    position: relative;
    top: 15px;
    font-weight: 300;
    margin-top: 4px;
    padding-left: 5px;
  }
  
  .google-map .phone {
    font-size: 16px;
    text-align: center;
    position: relative;
    top: -6px;
    font-weight: 300;
  }
  
  .google-map .email {
    font-size: 16px;
    text-align: right;
    position: relative;
    top: -29px;
    font-weight: 300;
  }
  
  footer {
    background-color: #303440;
    padding: 40px 0 40px;
    z-index: 10;
    position: relative;
    color: rgba(236, 240, 241, 0.4);
  }
  
  footer .copyright {
    text-align: center;
    font-size: 16px;
    color: #808393;
  }
  
  footer a {
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    color: rgba(236, 240, 241, 0.4);
  }
  
  footer a:hover {
    color: rgba(236, 240, 241, 1);
    text-decoration: none;
  }
  
  .mt-5{
    margin-top: 30px
  }
  
  
  @media only screen and (max-width: 2560px) {
    header .actions:after {
      left: 51%;
    }
  }
  @media only screen and (max-width: 1366px) {
    header .actions:after {
      left: 51.5%;
    }
  }
  @media only screen and (max-width: 1199px) {
    
    .logo{
      margin-bottom: 45px;
    }
    
  }
  
  @media only screen and (max-width: 1036px) {
    .services .col-md-4 {
      margin-bottom: 40px;
    }
  
    .subscribe button {
      width: 10%;
    }
  
    .contact input[type=submit] {
      width: 20%;
    }
  
    .contact .error .name {
      left: 43%;
    }
  
    header .actions:after {
      left: 51.9%;
    }
  
    .contact .notification_ok {
      top: 110%;
    }
  
    header .actions {
      margin: 10.5em 50px 0 0;
    }
  }
  
  @media only screen and (max-width: 991px) {
    
    .container{
      text-align: center;
    }
    
    header p { 
      justify-content: center;
    }
    
    header table {
      margin: 0 auto; 
    }
    
    .smt-5{
      margin-top: 30px
    }
    
    .sd-none{
      display: none;
    }
    
    
  }
  
  @media only screen and (max-width: 768px) {
    header .actions {
      margin: 15.5em 50px 0 0;
    }
  
    .subscribe input[type=email] {
      margin-bottom: 10px;
    }
  
    .subscribe button, .subscribe input[type=email] {
      width: 10%;
    }
  
    .about .col-md-4 {
      padding-bottom: 50px !important;
      text-align: center;
    }
  
    .about img {
      width: 50%;
      display: block;
      margin: 0 auto;
    }
  
    .about h2, .about p {
      text-align: center;
    }
  
    .about .social-icons {
      display: inline-block;
      margin-left: -40px;
    }
  
    .google-map .phone, .google-map .email {
      position: relative;
      margin: 0 auto;
      display: block;
      text-align: center;
    }
  
    .google-map .phone {
      top: -27px;
      color: #fff;
    }
  
    .google-map .email {
      top: -70px;
    }
  
    .contact input[type=submit] {
      width: 20%;
      margin: 0 auto;
      float: none;
    }
  
    .google-map i.fa-map-marker {
      margin-left: -5px;
    }
  
    .contact .error .name {
      left: 42.5%;
    }
  
    .contact .error .message {
      bottom: 95px;
    }
  
    .contact .notification_ok {
      text-align: center;
    }
  
    .google-map .contact-info {
      padding-top: 40px;
      height: 90px;
      text-align: center;
    }
  
    header .actions:after {
      left: 52.5%;
    }
  
    .countdown {
      font-size: 5em;
    }
  
    .countdown div {
      display: inline-block;
      width: 180px;
      padding-top: 12px;
    }
  
    .services .icon i.fa {
      float: none;
      text-align: center;
    }
  
    .services .col-md-4, .services p {
      text-align: center;
      margin-left: 0;
    } 
  }
  @media only screen and (max-width: 667px) {
   
    .logo img {
      height: auto;
      width: 50%;
    }
  }
  @media only screen and (max-width: 568px) {
    header .social-icons li {
      float: none;
      margin: 0 20px 15px 0;
    }
  
    .contact input[type=submit] {
      width: 27%;
    }
  
    .contact .error .name {
      left: 41.6%;
    }
  
    header img {
      width: 50%;
    }
  
    header .actions:after {
      left: 58%;
    }
  
    footer .copyright {
      font-size: 15px;
    }
  
    .countdown {
      font-size: 5em;
    }
  
    .countdown div {
      width: 130px;
    }
  }
  @media only screen and (max-width: 414px) {
    header img {
      width: 100%;
    }
  
    .contact .error .name {
      left: 39.9%;
    }
  
    .contact input[type=submit] {
      width: 47%;
    }
  
    .subscribe input[type=text] {
      width: 60%;
    }
  
    .subscribe button, .subscribe input[type=email] {
      width: 20%;
    }
  
    .countdown .border_clock {
      border-left: none;
    }
  
    header .actions {
      margin: 5em 50px 0 0;
    }
  
    header .actions:after {
      left: 56%;
    }
  } 
  @media only screen and (max-width: 320px) {
    header .actions:after {
      left: 57%;
    }
}

 
ul {
    padding: 0;
    margin: 0;
}

ul>li {
    list-style: none;
    margin: 8px 0;
    line-height: 1.5;
}

/* 1.2 Typography  */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    line-height: 1.6;
}

@media screen and (min-width: 768px) {
    h1 {
        line-height: 1.2;
        margin-bottom: 1rem;
    }
}

h2 {
    font-size: 1.75rem;
    line-height: 1.3;
}

h3 {
    font-size: 1.5rem;
    line-height: 1.3;
}

h4 {
    font-size: 1.25rem;
    line-height: 1.3;
}

h5 {
    font-size: 1rem;
    font-weight: 400;
}

h6 {
    font-size: .87rem;
    font-weight: 400;
    line-height: 1.5;
}

p {
    font-size: 1rem;
    line-height: 1.6;
}

.text-muted {
    color: #495057 !important;
}

.space {
    letter-spacing: .5px;
}

/* 1.3 Links */

a {
    color: #fff;
    transition: color .3s ease-in-out;
    -moz-transition: color .3s ease-in-out;
    -ms-transition: color .3s ease-in-out;
    -o-transition: color .3s ease-in-out;
    -webkit-transition: color .3s ease-in-out;
}

a:hover,
a:focus {
    color: #fff;
    text-decoration: underline;
    outline: none;
}

/* 1.4 Btn */

.btn {
    background-color: #007BFF;
    font-weight: 600;
    padding: 12px 18px;
    border: none;
    color: white;
    border-radius: 2.5px;
    -webkit-border-radius: 2.5px;
    -moz-border-radius: 2.5px;
    -ms-border-radius: 2.5px;
    -o-border-radius: 2.5px;
    transition: all .15s linear;
    -webkit-transition: all .15s linear;
    -moz-transition: all .15s linear;
    -ms-transition: all .15s linear;
    -o-transition: all .15s linear;
}

.btn:hover,
.btn:focus {
    background-color: #0062ff;
    color: white;
}

/* 1.5 Basic Reset */

.bg-color {
    background-color: rgba(248, 249, 250, 0.7);
}

.bg-color2 {
    background-color: rgba(248, 249, 250, 1);
}

.bg-dark {
    background-color: #111213 !important;
}

.bg-dark2 {
    background-color: #2a2c2e !important;
}

.home-wrapper {
    padding: 120px 0 120px;
    position: relative;
}

.bg-overlay {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.bg-overlay-8 {
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.home-center {
    position: absolute;
    top:0;
    display: table;
    width: 100%;
    height: 100%;
}

.home-center-desc {
    display: table-cell;
    vertical-align: middle;
}

.opa {
    position: relative;
    z-index: 1;
}

.justify-vertical {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

@media (min-width: 992px) {
    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
}

.no-gutter>.col,
.no-gutter>[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
}

.fullscreen {
    min-height: 100vh !important;
}

.shadow {
    box-shadow: 0px 0px 50px -35px rgba(0, 0, 0, 0.4) !important;;
}

.fixed-bottom {
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

.fullscreen--2 {
    min-height: 88vh !important;
}

@media (min-width: 992px) {
    .fixed-bottom {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1030;
    }
}

/* 1.0 Reset end */

/* 2 Header */

.sale {
    font-size: 3.2rem;
    font-weight: 500;
    padding: .5rem 0;
    line-height: 1.3;
}

.head {
    font-size: 4.7rem;
    font-weight: 700;
    line-height: 1.2;
}
 
@media (min-width: 992px) {
    .hero-3 .fullwidth {
        min-height: 100vh;
    }
}

@media (max-width: 767.98px) {
    .sale {
        font-size: calc(100% + 3vw);
    }

    .head {
        font-size: calc(100% + 7vw);
    }

    header ul > li {
        font-size: calc(60% + 1vw);
    }
}

@media (max-width: 575.98px) {
    .sale {
        font-size: calc(100% + 2.2vw);
    }

    .head {
        font-size: calc(100% + 6vw);
    }

    .sale-item span {
        font-size: calc(100% + 1.2vw);
    }

    .sale-item p {
        font-size: calc(80% + 1vw);
    }
}

@media (max-width: 319.98px) {
    .sale {
        font-size: calc(80% + 2vw);
    }

    .head {
        font-size: calc(80% + 5vw);
    }

    .sale-item span {
        font-size: calc(80% + 1vw);
    }

    .sale-item p {
        font-size: calc(60% + 1vw);
    }
}

.sale-form p {
    font-size: 14px;
}

.custom-form .form-group {
    margin-bottom: 1rem;
}

.custom-form input.form-control,
.custom-form textarea.form-control {
    background-color: transparent;
    font-size: 15px;
    border: 2px solid #ced4da;
}

.custom-form input.form-control {
    height: 47px;
}

.dark .custom-form input.form-control {
    color: #fff;
}

.custom-form input.form-control:hover,
.custom-form textarea.form-control:hover,
.custom-form input.form-control:focus,
.custom-form textarea.form-control:focus {
    box-shadow: none;
    border-color: #3389ff;
}
